<script>
import { mapState } from 'vuex'
import SSOModuleGuard from '@/mixins/ModulesGuards/SSO/SSOModuleGuard'

export default {
	name: 'EverialSSO',
	mixins: [SSOModuleGuard],
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		})
	},
	mounted: function () {
		this.service.getECMSSO(this.accountingFirm.id, 'everial').then(url => {
			window.open(url, '_target')
			this.appService.goTo('home')
		})
	}
}
</script>
